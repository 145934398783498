import * as types from "../types/index";
import axios from "axios";
import toast from "react-hot-toast";
import { refresh_profile, broker_logout } from "./authActions";
import { authHeader } from "../headers";

const url = "https://nasdmobile.nasdng.com/api/v1";
// const url = "http://146.190.205.147:3011/v1";

export const settlement_account = (datas, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.SETTLEMENT_REQUEST });

    const {
      auth: { detail },
    } = getState();

    const getData = window.localStorage.getItem("devoper");

    const { data } = await axios.put(
      `${url}/users/settlement-account/${id}?application=${getData}`,
      datas,
      {
        headers: authHeader(detail.token),
      }
    );

    if (data) {
      console.log(data.id, "refreah");
      dispatch({ type: types.SETTLEMENT_SUCCESS, payload: data });
      dispatch(refresh_profile(data.id));
      toast.success(`Settlement account has been submitted.`, {
        position: "top-right",
      });
    }
  } catch (error) {
    const message = error.response.data.error
      ? error.response.data.error
      : "Something went wrong";
    if (message === "Something went wrong") {
      dispatch(broker_logout());
    }
    dispatch({ type: types.SETTLEMENT_FAIL, payload: message });
    toast.error(message, { position: "top-right " });
  }
};

export const get_all_pouchii_locked_transactions =
  (requestData) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.POUCHII_TRANSACTION_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(
        `${url}/pouchii/transactions?application=${getData}&filter=${requestData.filter}`,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({ type: types.POUCHII_TRANSACTION_SUCCESS, payload: data });
      }
    } catch (error) {
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({ type: types.POUCHII_TRANSACTION_FAIL, payload: message });
      toast.error(message, { position: "top-right " });
    }
  };
export const get_all_pouchii_unlocked_transactions =
  (requestData) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.POUCHII_UNLOCK_TRANSACTION_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(
        `${url}/pouchii/transactions?application=${getData}&filter=${requestData.filter}`,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({
          type: types.POUCHII_UNLOCK_TRANSACTION_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({
        type: types.POUCHII_UNLOCK_TRANSACTION_FAIL,
        payload: message,
      });
      toast.error(message, { position: "top-right " });
    }
  };
export const get_all_pouchii_released_transactions =
  (requestData) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.POUCHII_RELEASE_TRANSACTION_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(
        `${url}/pouchii/transactions?application=${getData}&filter=${requestData.filter}`,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({
          type: types.POUCHII_RELEASE_TRANSACTION_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({
        type: types.POUCHII_RELEASE_TRANSACTION_FAIL,
        payload: message,
      });
      toast.error(message, { position: "top-right " });
    }
  };

export const get_all_banks = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_ALL_BANKS_REQUEST });

    const {
      auth: { detail },
    } = getState();

    const getData = window.localStorage.getItem("devoper");

    const { data } = await axios.get(
      `${url}/wallet/banks/list?application=${getData}`,
      {
        headers: authHeader(detail.token),
      }
    );

    if (data) {
      dispatch({ type: types.GET_ALL_BANKS_SUCCESS, payload: data });
    }
  } catch (error) {
    const message = error.response.data.error
      ? error.response.data.error
      : "Something went wrong";
    // if (message === "Something went wrong") {
    //   dispatch(broker_logout());
    // }
    // dispatch({ type: types.GET_ALL_BANKS_FAIL, payload: message });
    toast.error(message, { position: "top-right " });
  }
};
